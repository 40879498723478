<script>
import InfoHelper from '../../../components/InfoHelper.vue';
import AnyPageTemplate from '../../../components/PageTemplates/AnyPage.vue';
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import FormField from '../../../components/Panels/FormField.vue';
import CheckboxFormField from '../../../components/Panels/CheckboxFormField.vue';
import Uppercase from '../../../components/Utilities/Uppercase.vue';
import WizardStep from './components/WizardStepDirective.vue';
import { CompetitionTypesEnum, CompetitionScopesEnum, CompetitionRegistrationModesEnum } from '../../../Constants/'

export default{
    components:{ AnyPageTemplate, CollapsePanel, InfoHelper, Uppercase, WizardStep, FormField, CheckboxFormField},
    data(){
        return {
            wizStep: 0,
            competition: {
                competition_type: null,
                scope: null,
                name: '',
                location: '',
                isForSimulationPurposeOnly: this.isTestMode,
                predicted_start_date: null,
                predicted_end_date: null,
                isOpen: false,
                registrationMode: null,
                card_registration_message: null,
            },
        }
    },
    computed: {
        stepIsValid(){
            switch(this.wizStep){
                case 0:
                    return this.competition.competition_type != null && this.competition.competition_type != '';
                case 1:
                    return this.competition.location != null && this.competition.location != ''
                        && this.competition.predicted_start_date != null;
                case 2:
                    return true;
                case 3:
                    if(this.competition.registrationMode == null)
                        return false;
                    if(this.registration_limit_date_required && this.competition.registration_limit_date == null)
                        return false;
                    return true;
            }
            return false;
        },
        competitionType:{
            get(){
                if(this.competition.competition_type == CompetitionTypesEnum.CDF_OUT){
                    if(this.competition.scope == CompetitionScopesEnum.nation)
                        return "CDF_OUT_NATION";
                    return 'CDF_OUT_REGION';
                }
                return null;
            },
            set(newValue){{
                switch(newValue){
                    case 'CDF_OUT_NATION':
                        this.competition.competition_type = CompetitionTypesEnum.CDF_OUT;
                        this.competition.scope = CompetitionScopesEnum.NATION;
                        break;
                    case 'CDF_OUT_REGION':
                        this.competition.competition_type = CompetitionTypesEnum.CDF_OUT;
                        this.competition.scope = CompetitionScopesEnum.REGION;
                        break;
                }
            }}
        },
        competitionTypesOptions(){
            return [
                { value: null, text: 'choisissez...'}, 
                { value: 'CDF_OUT_REGION', text: this.$t('pages.competition-admin.common.competition-types.cdf-out-region.select') },
                { value: 'CDF_OUT_NATION', text: this.$t('pages.competition-admin.common.competition-types.cdf-out-nation.select') },
            ];
        },
        competitionTypesLongDesc(){
            if(this.competition.competition_type == CompetitionTypesEnum.CDF_OUT){
                if(this.competition.scope == CompetitionScopesEnum.NATION)
                    return this.$t('pages.competition-admin.common.competition-types.cdf-out-nation.long-desc');
                return this.$t('pages.competition-admin.common.competition-types.cdf-out-region.long-desc');
            }
            return '';
        },
        competitionRegistrationModessOptions(){
            var ret = [
                { value: null, text: 'choisissez...'},
                { value: CompetitionRegistrationModesEnum.N, text: this.$t('meta.competition-registration-modes.N.select')},
                { value: CompetitionRegistrationModesEnum.A, text: this.$t('meta.competition-registration-modes.A.select')},
                { value: CompetitionRegistrationModesEnum.S, text: this.$t('meta.competition-registration-modes.S.select')},
                { value: CompetitionRegistrationModesEnum.R, text: this.$t('meta.competition-registration-modes.R.select')},
                { value: CompetitionRegistrationModesEnum.C, text: this.$t('meta.competition-registration-modes.C.select')},
           ];
           console.log(ret);
           return ret;
        },
        registration_limit_date_possible(){
            switch(this.competition.registrationMode){
                case null:
                case CompetitionRegistrationModesEnum.N:
                case CompetitionRegistrationModesEnum.A:
                    return false;
                default:
                    return true;
            }
        },
        registration_limit_date_required(){
            switch(this.competition.registrationMode){
                case CompetitionRegistrationModesEnum.N:
                case CompetitionRegistrationModesEnum.A:
                case CompetitionRegistrationModesEnum.S:
                    return false;
                default:
                    return true;
            }
        },
        registration_limit_date_caption(){
            switch(this.competition.registrationMode){
                case CompetitionRegistrationModesEnum.S:
                    return "Date limite de confirmation des qualifiés (optionel)";
                case CompetitionRegistrationModesEnum.C:
                    return "Date limite des inscriptions et confirmation (pour les qualifiés)";
                case CompetitionRegistrationModesEnum.R:
                    return "Date limite des inscriptions";
            }
            return null;
        },
        endDateMin(){
          return (this.competition.predicted_start_date != null)
                 ? this.competition.predicted_start_date
                 : new Date();
        },
        canBeOpen(){
            return this.competition.competition_type == CompetitionTypesEnum.CDF_OUT;
        },
        isCdfOut(){
            return this.competition.competition_type == CompetitionTypesEnum.CDF_OUT;
        },
    },
    methods: {
        cancelCreation_click(){
            this.$router.push({name: 'compet-admin-list'});
        },
        nextStep_click(){
            this.wizStep++;
        },
        previousStep_click(){
            this.wizStep--;
        },
        validate_click(){
            this.$router.push({name: 'compet-admin-list'});
        },
        registrationMode_change(){
            this.competition.regi
        }
    },
    mounted(){
        this.competition.isForSimulationPurposeOnly = this.isTestMode;
    }
}
</script>

<template>
    <any-page-template>
        <template #main-title-caption>{{ $t('pages.competition-admin.create.title') }}</template>
        <template #page-intro>            
            <div v-if="isTestMode">
              <uppercase><strong>{{ $t('pages.competition-admin.common.simulation-mode')}}</strong></uppercase>
              <info-helper>
                <b-checkbox switch v-model="competition.isForSimulationPurposeOnly" disabled>{{ $t('pages.competition-admin.create.simulation-mode') }}</b-checkbox>
                <p v-html="$t('pages.competition-admin.create.simulation-mode-explain')"></p>
            </info-helper>
            </div>
        </template>
        <collapse-panel
            :title="$t('pages.competition-admin.common.general-pane.title')"
            :collapsable="false"
        >
            <wizard-step step="0" v-model="wizStep" :directive="$t('pages.competition-admin.create.steps.0')">
                <form-field class="col-xs-6" :caption="$t('models.competition.competition-type.normal')" required >
                    <div style="display:flex; flex-direction: column;">
                        <b-select 
                            v-model="competitionType"
                            :disabled="wizStep != 0"
                            :options="competitionTypesOptions"
                        ></b-select>
                        <div v-if="isCdfOut && false">{{ $t('meta.competition-types.cdf-out.long-desc') }}</div>
                        <div>{{ competitionTypesLongDesc }}</div>
                    </div>
                </form-field>
            </wizard-step>

            <wizard-step step="1" v-model="wizStep" :directive="$t('pages.competition-admin.create.steps.1')">
                <form-field
                    class="col-xs-6"
                    :caption="$t('models.competition.location.normal')"
                    required
                >
                  <b-form-input type="text" v-model="competition.location" :disabled="wizStep != 1" required debounce="750" />
                </form-field>

                <form-field
                    class="col-xs-6"
                    :caption="$t('models.competition.predicted_start_date.normal')"
                    required
                >
                  <b-form-datepicker v-model="competition.predicted_start_date" :min="new Date()" placeholder="choisissez..." class="startDate" :locale="$t('language-selector.date-picker-locale')" :disabled= 'wizStep != 1' debounce="750" />
                </form-field>
            </wizard-step>

            <wizard-step step="2" v-model="wizStep" :directive="$t('pages.competition-admin.create.steps.2')">
                <form-field
                    class="col-xs-6"
                    v-if="competition.predicted_end_date != null || wizStep == 2"
                    :caption="$t('models.competition.predicted_end_date.normal')"
                    :required="false"
                >
                  <b-form-datepicker v-model="competition.predicted_end_date" :min="endDateMin" class="endDate" :locale="$t('language-selector.date-picker-locale')" placeholder="choisissez..." :disabled="competition.predicted_start_date==null || wizStep != 2" required debounce="750" />
                </form-field>
            </wizard-step>


            <wizard-step step="3" v-model="wizStep" :directive="$t('pages.competition-admin.create.steps.3')">
                <checkbox-form-field 
                    v-if="canBeOpen"
                    class="col-xs-6"
                    :caption="$t('models.competition.isOpen.normal')"
                    v-model="competition.isOpen" 
                    :disabled="wizStep != 3" >
                </checkbox-form-field>

                <form-field 
                    class="col-xs-6"
                    :caption="$t('models.competition.registrationMode.normal')" 
                    required
                >
                    <b-select
                        v-model="competition.registrationMode"
                        :disabled="wizStep != 3"
                        :options="competitionRegistrationModessOptions"
                        @change="registrationMode_change"
                    ></b-select> 
                </form-field>

                <form-field
                    v-if="registration_limit_date_possible"
                    :disabled="wizStep != 3"
                    class="col-xs-6"
                    :caption="registration_limit_date_caption"
                    :required="registration_limit_date_required"
                >
                    <b-form-datepicker v-model="competition.registration_limit_date" :min="new Date()" :max="competition.predicted_start_date" class="endDate" :locale="$t('language-selector.date-picker-locale')" placeholder="choisissez..." :disabled="wizStep != 3" required debounce="750" />
                </form-field>
            </wizard-step>

            <wizard-step step="4" v-model="wizStep" :directive="$t('pages.competition-admin.create.steps.4')">
                <form-field
                    :caption="$t('models.competition.card_registration_message')"
                >

                </form-field>
            </wizard-step>

        </collapse-panel>

        <div style="display:flex; justify-content: space-between;">
            <b-button @click="cancelCreation_click">{{ $t('pages.competition-admin.create.cancel-creation') }}</b-button>
            <div style="display:flex; justify-content: flex-end;">
                <b-button @click="previousStep_click" v-show="wizStep > 0">{{ $t('pages.competition-admin.create.previousStep') }}</b-button>
                <b-button @click="nextStep_click" :disabled="!stepIsValid">{{ $t('pages.competition-admin.create.nextStep') }}</b-button>
                <b-button @click="validate_click" disabled>{{ $t('pages.competition-admin.create.validate') }}</b-button>
            </div>
        </div>
    </any-page-template>
</template>